// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-container {
    margin: 30px;
}

.privacy-item-header {
    line-height: 30px;
    padding-bottom: 5px;
    padding-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/routes/Privacy/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".privacy-container {\n    margin: 30px;\n}\n\n.privacy-item-header {\n    line-height: 30px;\n    padding-bottom: 5px;\n    padding-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
