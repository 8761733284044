import './App.css';
import createStore from './store/createStore'
import { Provider } from 'react-redux'
import { Router } from 'react-router'
import { createBrowserHistory } from 'history'
import { injectReducer } from './store/reducers'
import App from './App'
import React from 'react'

const store = createStore(window.__INITIAL_STATE__)
const customHistory = createBrowserHistory()

// const mapDispatchToProps = {
//     auth : auth,
// }

// const mapStateToProps = (state) => ({
//     token: state.auth.token
// })

// const reducer = require('./AppReducer').default
// injectReducer(store, { "key": "auth",  reducer })

const AppWrapper = () => {
    return (
        <Provider store={store}>
            <Router history={customHistory}>
                <App/>
            </Router>
        </Provider>
    )
}

export default AppWrapper
