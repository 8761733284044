import React, { Fragment, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router';
import axios from 'axios'
import '../style.css'
import { connect } from 'react-redux'

const mapStateToProps = (state) => ({})

const HomePage = () => {
    

    return (
        <div className="login_container">
            {/* <img src={logo} className="login_logo" alt="logo"/> */}
            Hello 🎄

            <canvas id="qr-code"></canvas>
        </div>
    )
}

export default connect(mapStateToProps, null)(HomePage)
