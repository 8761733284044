import './App.css';
import { connect, ReactReduxContext } from 'react-redux'
import { matchPath, useHistory } from 'react-router'
import createRoutes from './routes'
import React, { useEffect, Fragment, useContext, useState } from 'react'
import { auth } from './AppReducer'

// const store = createStore(window.__INITIAL_STATE__)
// 
// const mapDispatchToProps = {
//     auth : auth,
// }

function mapStateToProps(state) {
    console.log('map to state')
    console.log(state)

    return {
        user: state.auth?.user
    }
}

const mapDispatchToProps = {
    auth: auth
}

const App = ({ user, auth }) => {
    const { store } = useContext(ReactReduxContext)

    // const history = useHistory()
    const routes = createRoutes(store)

    // const [isAuthed, setIsAuthed] = useState(false)

    // useEffect(() => {
    //     var unsubscribeCallback = null
    //     if (!isAuthed) {
    //         auth()
    //         setIsAuthed(true)

    //         unsubscribeCallback = history?.listen((location, action) => {
    //             checkLocation(location.pathname)
    //         })
    //     }

    //     checkLocation(history.location.pathname)

    //     return () => {
    //         if (unsubscribeCallback != null) {
    //             console.log('unsubscribe')
    //             unsubscribeCallback()
    //         }
    //     }
    // }, [isAuthed, history, auth, user])

    // function checkLocation(location) {
    //     if (user != null && Object.keys(user).length > 0) {
    //         let match = matchPath(location, {
    //             path: "/login",
    //             exact: false,
    //             strict: false
    //         })

    //         if (match != null) {
    //             history.replace('/')
    //         }
    //     } else {
    //         var hasMatch = false
    //         for (let i = 0; i < routes.length; i++) {
    //             const route = routes[i];

    //             let match = matchPath(location, {
    //                 path: route.props.path,
    //                 exact: false,
    //                 strict: false
    //             })

    //             if (match != null && route.props.isProtected) {
    //                 hasMatch = true
    //                 redirectToSign()
    //                 break
    //             } else if (match != null && !route.props.isProtected)  {
    //                 hasMatch = true
    //                 break
    //             }
    //         }

    //         if (!hasMatch) {
    //             redirectToSign()
    //         }
    //     }
    // }

    // function redirectToSign() {
    //     console.log('redirect to sign')
    //     history.replace('/login')
    // }

    return (
        <Fragment>
            { routes }
        </Fragment>
    )
}

// connect
export default connect(mapStateToProps, mapDispatchToProps)(App)
