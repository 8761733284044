import { Route } from 'react-router'
import Page from './Container/'
import React from 'react'

const RootRoute = (store) => {
    return (
        <Route path='/' key='home' isProtected={true} exact={true}>
            <Page store={store}/>
        </Route>
    )
}

export default RootRoute
