// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    height: 100vh;
    flex-direction: column;
    margin: auto;
}

.login_logo {
    width: 200px;
    height: 200px;
}

.phone_input {
    margin-top: 50px;
    margin-bottom: 12px;
    height: 40px;
    border: 1px solid lightgray;
    padding: 8px;
    border-radius: 5px;
    width: 290px !important;
}

.login_button {
    width: 290px;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Root/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,2BAA2B;IAC3B,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".login_container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex: 1;\n    height: 100vh;\n    flex-direction: column;\n    margin: auto;\n}\n\n.login_logo {\n    width: 200px;\n    height: 200px;\n}\n\n.phone_input {\n    margin-top: 50px;\n    margin-bottom: 12px;\n    height: 40px;\n    border: 1px solid lightgray;\n    padding: 8px;\n    border-radius: 5px;\n    width: 290px !important;\n}\n\n.login_button {\n    width: 290px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
