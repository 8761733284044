import axios from 'axios'
import update from 'immutability-helper'

import * as constants from './const/Api'
const URL = constants.URL

export const AUTH = "AUTH"
export const VERIFY = "VERIFY"

/// ACTIONS

export function auth() {
    return (dispatch, getState) => {
        var url = URL + '/user/auth?token=' + getState().auth.token

        return axios.all([axios.get(url)]).then(axios.spread(function (res) {
            if (!res.data.success) {
                alert(JSON.stringify(res.data))
            } else {
                console.log(res.data)
                localStorage.setItem("@TOKEN", res.data.session.token)

                dispatch({
                    type: AUTH,
                    payload: {
                        token: res.data.session.token,
                        user: res.data.session.user
                    }
                })
            }
        }))
    }
}

export const actions = {
    auth: auth
}

/// ACTION HANDLERS

const ACTION_HANDLERS = {
    [AUTH]: (state, action) => {
        console.log('auth complete')
        console.log(action.payload)

        localStorage.setItem('@USER', JSON.stringify(action.payload.user))
        return update(state, {
            token: {
                $set: action.payload.token
            },
            user: {
                $set: action.payload.user
            }
        })
    },

    [VERIFY]: (state, action) => {
        console.log(action.payload)

        localStorage.setItem('@USER', JSON.stringify(action.payload.user))
        if (action.payload.user != null) {
            return update(state, {
                user: {
                    $set: action.payload.user
                }
            })
        }

        return state
    }
}

/// REDUCER

const initialState = {
    token: localStorage.getItem('@TOKEN'),
    user: JSON.parse(localStorage.getItem('@USER'))
}

export default function complaintsReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]

    return handler
        ? handler(state, action)
        : state
}
