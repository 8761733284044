import React, { Fragment, useEffect, useState } from 'react';
import '../style.css'
import { connect } from 'react-redux'


const mapStateToProps = (state) => ({ })

const PrivacyPage = () => {

    return (
        <div className="privacy-container">
            
<h2 className="privacy-item-header">INTRODUCTION</h2>

We respects your privacy and is committed to protecting your Personal Data. We want to be transparent with you about how we collect and use your Personal Data in making available our games, (“Site”) and tell you about your privacy rights and how the law protects you.

<h2 className="privacy-item-header">HOW & WHEN WE COLLECT YOUR PERSONAL DATA</h2>

From the moment you begin interacting with us, we are collecting information. Sometimes the personal data we collect is provided by you and sometimes it is collected automatically.

You give us data when you: register or update a account, update your profile or other account details, use our paid-for services, apply for a job with us, attend an interview or assessment, opt-in to our marketing messages, subscribe to our mailing lists, call us, email us, live chat with us online, chat to us in-game, make a purchase from us, enter one of our competitions and/or answer one of our surveys, fill in a form conduct a search or post content on our website, interact with other users on our online services, register to attend our events or ‘follow’, ‘like’, post to or interact with our social media accounts.

We collect your data automatically when you: browse and/or use any of our website pages, interact with other users on our online services, receive an email from us, log into your account, play one of our games, live chat with us online, chat to us or other users in-game or make a purchase from us.

<h2 className="privacy-item-header">WHAT INFORMATION DO WE COLLECT?</h2>

We aim to minimize how much personal information we collect from users when they play the Games. We collect the personal information that you voluntarily provide to us, but most of our data collection is limited to the following types of data, which do not identify you personally but may uniquely identify the device you are using to play to the Games.

Device and Usage Information that may include：

<br></br>(i) information specific to your mobile device (e.g., make, model, operating system, advertising identifier and similar information);

<br></br>(ii) information about your use of features, functions, or notifications on the device;

<br></br>(iii) signal strength relating to WiFi or Bluetooth functionality, temperature, battery level, and similar technical data.

<h2 className="privacy-item-header">WHY & HOW WE USE YOUR PERSONAL DATA</h2>

We will only use your personal data for certain specified reasons and only where we have a lawful basis to do so. Below are the reasons we use your personal information and the lawful bases for doing so:

Registering your account

When you sign up to use our website, we will use the details provided on your account registration form.

Our lawful basis for this use of your data is: performing our contract with you.

Keeping our websites and gaming services running

Providing you our games and services online, login authentication, age verification, remembering your settings, processing payments, hosting and back-end infrastructure and access to our websites.

Our lawful bases for this use of your data are: performing our contract with you, to comply with our legal obligations or legitimate interests to keep our services running.

Enabling you to communicate with other users

Certain parts of our websites and online services enable you to communicate and interact with other users. We will use information you have provided us to enable these interactions and communications on our websites and online services.

Our lawful basis for this use of your data is: performing our contract with you.

Processing payments

We will use your information to process payments for our goods and services.

Our lawful basis for this use of your data is: performing our contract with you.

Targeted advertising

Through cookies, clear GIFs, web beacons and tracking pixels we will use your information to deliver relevant advertisements and offers to you and measure the effectiveness of the advertising and offers. These technologies connect your behaviour across different websites, mobile apps and devices and enable tailored advertising to be served to your game, web browser, or mobile device.

Our lawful basis for this use of your data is: your consent.

Social media

We will use your information to communicate with you where you message us, respond to our posts, “like” our posts, tweet or retweet us or otherwise interact with us directly on social media platforms.

Our lawful bases for this use of your data are: our legitimate interests in promoting our brand and communicating with interested individuals.

Anti-cheat, anti-tampering, fraud and unlawful activity detection and prevention

To enforce our rules and policies, protect our customers and business, protect the competitive integrity of our games, investigate, and deter against fraudulent, unauthorised or illegal activity.

Our lawful bases for this use of your data are: our legitimate interests to operate a safe and lawful business or where we have a legal obligation to do so.

Community and customer support

Handling all enquiries or complaints, troubleshooting and solving technical issues over live chat, phone, email and in-game chat, making necessary changes to our products and services and monitoring your participation in our forums and all parts of our services that allow you to publicly post information or interact with other users.

Our lawful bases for this use of your data are: performing our contract with you, our legitimate interests to provide you with customer service, our legitimate interest in providing the correct products and services to our website users or to comply with our legal obligations.

Improving and developing our games, services and websites

Traffic optimisation, in-game data collection, analytics and research, including profiling and the use of machine learning (we sometimes use carefully selected third parties to do this on our behalf), cheat detection and prevention, managing landing pages and heat mapping our sites.

Our lawful bases for this use of your data are: performing our contract with you and our legitimate interests in learning about the types of people who are interested in our games, websites and services, to keep our games, online services and websites updated and relevant, to develop our business and to inform our marketing strategy.

Contests

To process your entry into, contests, surveys or other promotional events that we run from time to time, should you decide to participate in them.

Our lawful bases for this use of your data is: your consent or contract.

Marketing

Sending you communications about our services, products and features that you have agreed to receive.

Our lawful bases for this use of your data are: your consent or our legitimate interests to provide you with marketing communications where we may lawfully do so.

<h2 className="privacy-item-header">KEEPING YOUR PERSONAL DATA SECURE</h2>

We treat your personal data with care and take reasonable steps to protect it, including the use of physical, technical and administrative safeguards to protect your personal data from unauthorised access, use or disclosure.

For this reason, we secure access to all transactional areas

HOW LONG WILL INVICTUS KEEP MY PERSONAL INFORMATION?

How long we store your personal information will depend on the purpose that we originally collected it. We will not retain personal information for longer than is necessary for our business, security or for legal requirements.

You can contact our data protection officer to request the deletion of your data through the contact information provided by us.

<h2 className="privacy-item-header">CONTACT US</h2>

If you have any questions, comments or concerns regarding our Privacy Policy and/or practices, please contact us: pangrizzly@gmail.com.

        </div>
    )
}

export default connect(mapStateToProps, null)(PrivacyPage)
