
import Privacy from './Container/PrivacyContainer'
import { Route } from 'react-router'
import React, { useEffect } from 'react'


const PrivacyRoute = (store) => {
    return (
        <Route path='/privacy' key="privacy" isProtected={false} store={store}>
            <Privacy/>
        </Route>
    )
}

export default PrivacyRoute